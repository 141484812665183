
import { defineComponent } from "vue";

//molecules
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import StudentLetter from "@/components/molecules/student/StudentLatter.vue";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import Calendar from "@/components/molecules/OneDateCalendar.vue";
import CalendarDataRenge from "@/components/molecules/Calendar.vue";

//atoms
import Select from "@/components/atoms/CustomSelect.vue";

//svg
import ArrowBack from "@/assets/svg/arrow-back.svg?inline";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import PhotoMock from "@/assets/svg/photo-zaglushka.svg?inline";
import Edit from "@/assets/svg/edit-blue.svg?inline";
import Delete from "@/assets/svg/delete-blue.svg?inline";
import BlockUser from "@/assets/svg/block-user.svg?inline";
import UnblockUser from "@/assets/svg/unblock-user.svg?inline";
import DeactivateUser from "@/assets/svg/deactivate-user.svg?inline";
import AdditionalStudentIcon from "@/assets/svg/additional-student.svg?inline";
import CreateStudent from "@/assets/svg/create-student-name.svg?inline";
import EmailIcon from "@/assets/svg/create-student-email-icon.svg?inline";
import PhoneIcon from "@/assets/svg/login-phone-icon.svg?inline";
import UploadPhoto from "@/assets/svg/upload-photo.svg?inline";
import Search from "@/assets/svg/search.svg?inline";

//additional
import authHeader from "@/services/auth-header";
import vClickOutside from "click-outside-vue3";
import { Form, Field } from "vee-validate";

export default defineComponent({
  name: "Student",
  data() {
    return {
      isModalBlockUser: false,
      isStydentBanned: false,
      studentData: {},
      studentId: null,
      activeTab: 3,
      isFinancialManager: localStorage.getItem("financial-manager"),
      isSubscriptionEnabled: localStorage.getItem("is-subscription-enabled"),
      tabs: [
        {
          name: "Payments Log",
          isFinancialManager: localStorage.getItem("financial-manager"),
          isSubscriptionEnabled: localStorage.getItem(
            "is-subscription-enabled"
          ),
        },
        {
          name: "Subscription management",
          isFinancialManager: localStorage.getItem("financial-manager"),
          isSubscriptionEnabled: localStorage.getItem(
            "is-subscription-enabled"
          ),
        },
        {
          name: "Refunds",
          isFinancialManager: localStorage.getItem("financial-manager"),
          isSubscriptionEnabled: localStorage.getItem(
            "is-subscription-enabled"
          ),
        },
        {
          name: "Student`s history",
          isFinancialManager: "true",
          isSubscriptionEnabled: "true",
        },
      ],
      isCalendarVisible: false,
      isCalendarVisibleStydentBanned: false,
      refundsData: {},
      dataPeriod: [
        {
          title: "Month",
          value: "month",
        },
        {
          title: "2 months",
          value: "2_months",
        },
        {
          title: "3 months",
          value: "3_months",
        },
      ],
      periodSelected: "month",
      selectedNewDate: null,
      selectedNewDateHistory: null,
      selectedNewDateStydentBanned: null,
      errorSubscribe: null,
      errorMessageSubscribe: null,
      anayticsData: {},
      isModalRefunds: false,
      isModalSubscribed: false,
      selectedDatePlacholder: "Date",
      isPopupUnsibscribe: false,
      isModalChooseInstructorNewStudent: false,
      isModalConvertToIndividualStudent: false,
      activeStep: "1",
      isPreview: null,
      fullName: "",
      phone: null,
      email: null,
      errorInstructor: null,
      instructorsData: {},
      historyData: {},
      historyMeta: {},
      searchDataInstructors: "",
      inviteInstructorsData: [],
      inviteAdditionalInstructorsData: [],
      linkToQr: "",
      additionalStudentId: null,
      copied: false,
      dataNotification: [],
      filtersDateHistory: "",
      filters: "",
      childRegistrationLinkData: null,
      parentRegistrationLinkData: null,
      studentConvertId: null,
      isModalSeccess: false,
      isModalConvertSeccess: false,
    };
  },
  components: {
    ArrowBack,
    PhotoMock,
    Edit,
    Delete,
    BlockUser,
    UnblockUser,
    DeactivateUser,
    Select,
    DefaultPopup,
    SortArrow,
    SelectArrow,
    Calendar,
    CalendarDataRenge,
    AlertBlock,
    AdditionalStudentIcon,
    CreateStudent,
    EmailIcon,
    PhoneIcon,
    Form,
    Field,
    Search,
    UploadPhoto,
    StudentLetter,
  },
  watch: {
    searchDataInstructors() {
      this.getInstructors();
      this.page = 1;
    },
  },
  mounted() {
    this.studentId = this.$route.query.student;
    this.getStudent(this.studentId);
    this.refundsList(this.studentId);
    this.getPaymentsList();
    this.getInstructors();
    this.getHistoryStudent();
    this.getEventTypes();
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    getStudent(id) {
      this.$store.dispatch("students/getStudent", id).then(
        (res) => {
          this.studentData = res.data;
          this.isStydentBanned = this.studentData.banned;
          if (this.studentData.subscription !== null) {
            const date = new Date(this.studentData.subscription.period.to);
            const day = String(date.getUTCDate()).padStart(2, "0");
            const month = String(date.getUTCMonth() + 1).padStart(2, "0");
            const year = date.getUTCFullYear();
            this.selectedDatePlacholder = `${day}.${month}.${year}`;
          }
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    getPaymentsList(params, sort) {
      if (sort === undefined) {
        sort = "id";
      }
      params = {
        id: this.studentId,
        string: "?sort=" + sort,
      };
      this.$store.dispatch("students/getPaymentsList", params).then(
        (res) => {
          this.anayticsData = res.data;
          this.anayticsMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getHistoryStudent(data, sort) {
      if (sort == undefined) {
        sort = "";
      }
      data = {
        id: this.studentId,
        params: "?sort=" + sort + this.filtersDateHistory + this.filters,
      };
      this.$store.dispatch("students/getHistoryStudent", data).then(
        (res) => {
          this.historyData = res.data;
          this.historyMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getEventTypes() {
      this.$store.dispatch("students/getEventTypes").then(
        (res) => {
          this.dataNotification = Object.entries(res.data).map(
            ([key, value]) => ({
              title: value,
              value: key,
            })
          );
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getParentRegistrationLink(step, id) {
      const data = {
        id: id,
      };
      this.$store.dispatch("students/getParentRegistrationLink", data).then(
        (res) => {
          this.parentRegistrationLinkData = res.data;
          this.activeStep = step;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
          if (error.message == "Request failed with status code 500") {
            this.getParentRegistrationLink(step, id);
          }
        }
      );
    },
    openBlockUserPopup() {
      this.isModalBlockUser = true;
    },
    handleCloseModalBlockUser() {
      this.isModalBlockUser = false;
    },
    blockStudent() {
      this.$store.dispatch("students/banStudent", this.studentId).then(
        (res) => {
          this.getStudent(this.studentId);
          this.isModalBlockUser = false;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    unblockStudent() {
      this.$store.dispatch("students/unbanStudent", this.studentId).then(
        (res) => {
          this.getStudent(this.studentId);
          this.isModalBlockUser = false;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    subscribe(data) {
      data = {
        id: this.studentId,
        expiration_date: this.selectedNewDate,
      };
      this.$store.dispatch("students/subscribe", data).then(
        (res) => {
          this.subscribeData = res.data;
          this.getStudent(this.studentId);
          this.isModalSubscribed = true;
        },
        (error) => {
          this.errorSubscribe = error.response.data.errors;
          if (error.response.data.errors === undefined) {
            this.errorMessageSubscribe = error.response.data.message;
          }
        }
      );
    },
    unsubscribe(data) {
      data = {
        id: this.studentId,
      };
      this.$store.dispatch("students/unsubscribe", data).then(
        (res) => {
          this.isPopupUnsibscribe = false;
          this.getStudent(this.studentId);
        },
        (error) => {
          this.errorUnsubscribe = error.response.data.errors;
        }
      );
    },
    refunds(data) {
      data = {
        id: this.studentId,
        period: this.periodSelected,
      };
      this.$store.dispatch("students/refunds", data).then(
        (res) => {
          console.log(res);
          this.isModalRefunds = true;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    activeStudent(data) {
      data = {
        id: this.studentId,
        activation_date: this.selectedNewDateStydentBanned,
      };
      this.$store.dispatch("students/activate", data).then(
        (res) => {
          console.log(res);
          this.isModalBlockUser = false;
          this.getStudent(this.studentId);
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    deactiveStudent(data) {
      data = {
        id: this.studentId,
        deactivation_date: this.selectedNewDateStydentBanned,
      };
      this.$store.dispatch("students/deactivate", data).then(
        (res) => {
          console.log(res);
          this.isModalBlockUser = false;
          this.getStudent(this.studentId);
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    refundsList(data, sort) {
      if (sort === undefined) {
        sort = "id";
      }
      data = {
        id: this.studentId,
        string: "?sort=" + sort,
      };
      this.$store.dispatch("students/refundsList", data).then(
        (res) => {
          this.refundsData = res.data;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    handleOptionSelected(selectedOption) {
      this.periodSelected = selectedOption.value;
    },
    editstudent() {
      this.$router.push("/admin/edit-student?student=" + this.studentId);
    },
    toggleCalendarStydentBanned() {
      this.isCalendarVisibleStydentBanned =
        !this.isCalendarVisibleStydentBanned;
    },
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    closeCalendar() {
      this.isCalendarVisible = false;
    },
    closeCalendarStydentBanned() {
      this.isCalendarVisibleStydentBanned = false;
    },
    selectDateFill(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const Month = selectedDate.getMonth() + 1;
      let monthFormat = "";
      if (Month < 10) {
        monthFormat = "0" + (selectedDate.getMonth() + 1);
      } else monthFormat = (selectedDate.getMonth() + 1).toString();
      const Day = selectedDate.getDate();
      let dayFormat = "";
      if (Day < 10) {
        dayFormat = "0" + selectedDate.getDate();
      } else dayFormat = selectedDate.getDate().toString();
      this.selectedNewDate =
        selectedDate.getFullYear() + "-" + monthFormat + "-" + dayFormat;
      this.isCalendarVisible = false;
      this.errorSubscribe = null;
      this.errorMessageSubscribe = null;
    },
    selectDateFillHistory(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        return;
      }
      if (date.value !== "") {
        const selectedDate = new Date(year, month, date.value);
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate() >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
          this.selectedEndDate = null;
        }
      }
      if (this.selectedEndDate) {
        this.filtersDateHistory = `&filter[date_range]=${this.selectedStartDate},${this.selectedEndDate}`;
        this.getHistoryStudent();
        this.isCalendarVisible = false;
      }
    },
    selectDateFillStydentBanned(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const Month = selectedDate.getMonth() + 1;
      let monthFormat = "";
      if (Month < 10) {
        monthFormat = "0" + (selectedDate.getMonth() + 1);
      } else monthFormat = (selectedDate.getMonth() + 1).toString();
      const Day = selectedDate.getDate();
      let dayFormat = "";
      if (Day < 10) {
        dayFormat = "0" + selectedDate.getDate();
      } else dayFormat = selectedDate.getDate().toString();
      this.selectedNewDateStydentBanned =
        selectedDate.getFullYear() +
        "-" +
        monthFormat +
        "-" +
        dayFormat +
        " 23:59:59";
      this.isCalendarVisibleStydentBanned = false;
      this.isModalBlockUser = true;
    },
    handleCloseModalRefunds() {
      this.isModalRefunds = false;
    },
    handleCloseModalSubscribed() {
      this.isModalSubscribed = false;
    },
    openPopupUnsibscribe() {
      this.isPopupUnsibscribe = true;
    },
    handleCloseModalUnsubscribed() {
      this.isPopupUnsibscribe = false;
    },
    openPopupChoosenInstructorNewStudent(id) {
      this.studentId = id;
      this.isModalChooseInstructorNewStudent = true;
    },
    handleCloseModalChooseInstructorNewStudent() {
      this.isModalChooseInstructorNewStudent = false;
      if (this.additionalStudentId) {
        this.isModalSeccess = true;
      }
    },
    handleCloseModalSeccess() {
      if (this.additionalStudentId) {
        window.location.href = `/admin/student?student=${this.additionalStudentId}`;
      }
    },
    openPopupConvertToIndividualStudent(id) {
      this.studentId = id;
      this.isModalConvertToIndividualStudent = true;
    },
    handleCloseModalConvertToIndividualStudent() {
      this.isModalConvertToIndividualStudent = false;
      if (this.studentConvertId) {
        this.isModalConvertSeccess = true;
      }
    },
    handleCloseModalConvertSeccess() {
      if (this.studentConvertId) {
        window.location.href = `/admin/student?student=${this.studentConvertId}`;
      }
    },
    nextStep(step) {
      this.activeStep = step;
      this.errorInstructor = null;
    },
    prewStep(step) {
      this.activeStep = step;
    },
    getInstructors(params) {
      params = "?search=" + this.searchDataInstructors;
      this.$store.dispatch("instructors/getInstructors", params).then(
        (res) => {
          this.instructorsData = res.data;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    selectInstructors(id, event, name) {
      if (event.target.checked) {
        this.inviteInstructorsData.push(id);
        this.inviteAdditionalInstructorsData.push(name);
      } else {
        this.inviteInstructorsData = this.inviteInstructorsData.filter(
          (item) => item !== id
        );
        this.inviteAdditionalInstructorsData =
          this.inviteAdditionalInstructorsData.filter((item) => item !== name);
      }
    },
    createAdditionalStudent() {
      const API_URL = process.env.VUE_APP_URL;
      const formData = new FormData();
      formData.append("full_name", this.fullName);
      if (this.inviteInstructorsData.length > 0) {
        this.inviteInstructorsData.forEach((id) => {
          formData.append("instructor_ids[]", id);
        });
      }
      if (this.file !== undefined) {
        formData.append("image", this.file);
      }
      this.axios
        .post(
          API_URL +
            `admin/schools/${JSON.parse(
              localStorage.getItem("admin-school-id")
            )}/${this.$route.query.student}/children`,
          formData,
          {
            headers: authHeader(),
          }
        )
        .then(
          (res) => {
            this.nextStep("3");
            this.additionalStudentId = res.data.data.child_id;
          },
          (error) => {
            this.errorInstructor = error.response.data.errors;
            this.serverError = error.response.data;
            if (
              this.activeStep === "1" &&
              this.errorInstructor.image === undefined &&
              this.errorInstructor.full_name === undefined
            ) {
              this.nextStep("2");
            }
          }
        );
    },
    convertAndGenetare() {
      const API_URL = process.env.VUE_APP_URL;
      const formData = new FormData();
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      this.axios
        .patch(
          API_URL +
            `admin/schools/${JSON.parse(
              localStorage.getItem("admin-school-id")
            )}/${this.$route.query.student}/convert-to-normal`,
          {
            phone: this.phone,
            email: this.email,
          },
          {
            headers: authHeader(),
          }
        )
        .then(
          (res) => {
            this.studentConvertId = res.data.data[0].id;
            this.getParentRegistrationLink("5", res.data.data[0].id);
          },
          (error) => {
            this.errorInstructor = error.response.data.errors;
            this.serverError = error.response.data;
          }
        );
    },
    handleFileUpload() {
      this.isPreview = false;
      this.imagePreview = null;
      this.errorInstructor = null;
      this.file = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("thumbnail", this.file);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.imagePreview = reader.result;
          this.isPreview = true;
        }.bind(this),
        false
      );
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    getChildRegistrationLink(step) {
      const data = {
        id: this.additionalStudentId,
      };
      this.$store.dispatch("students/getChildRegistrationLink", data).then(
        (res) => {
          this.childRegistrationLinkData = res.data;
          this.activeStep = step;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    async copyText() {
      try {
        await navigator.clipboard.writeText(this.linkToQr);
        this.copied = true;
        setTimeout(() => (this.copied = false), 3000);
      } catch (error) {
        console.error("Failed to copy text: ", error);
      }
    },
    handleOptionSelectedEventType(selectedOption) {
      this.filters = `&filter[event_type]=${selectedOption.value}`;
      this.getHistoryStudent();
    },
    isChecked(id) {
      if (this.inviteInstructorsData) {
        return this.inviteInstructorsData.includes(id);
      }
    },
  },
});
